import React from 'react'
import { Page, Heading, Text } from '@psyomics/components'
import Header from 'ui/app/components/Header'
import Styles from './ClinicianIFU.module.scss'
import { IncidentReporting, SoftwareInformation, Warnings } from './shared/IFUCommon'

interface ClinicianIfuProps {
    showMenu?: boolean
}
const ClinicianIFU: React.FC<ClinicianIfuProps> = ({ showMenu = true }) => {
    return (
        <Page width="wide" header={showMenu ? <Header patientFacing={false}></Header> : <></>}>
            <Heading el="h1" size="heading1" css={{ mt: 5, mb: 5 }}>
                Clinician Instructions for Use
            </Heading>

            <Heading el="h2" size="heading2" css={{ mt: 6, mb: 5 }}>
                Intended Use
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                The &apos;Censeo Digital&apos; medical device is software intended for use as a clinical decision
                support tool to assess patients aged 18-65 with mental health concerns who, after referral into the
                pathway, is triaged and assessed by a mental health professional.
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                This medical device software uses patients&apos; answers to produce a mental health report which is
                provided to a Healthcare Professional (HCP) working in a mental health pathway. The report includes an
                indication of presenting mental health symptoms relating to mental health conditions. This report
                provides a consistent and comprehensive set of data that can be used in conjunction with other
                information to aid in the selection of subsequent patient care pathway options where diagnosis and
                treatment can be performed.
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                Censeo Digital is intended to be used at the start of an existing mental health triage pathway and must
                not be used:
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                <ol className={Styles.orderedList}>
                    <li>As a replacement for existing mental health triage and risk assessment conducted by HCPs.</li>
                    <li>As a tool to perform diagnostic tasks.</li>
                    <li>As a crisis support tool.</li>
                    <li>To determine eligibility for services / referral without clinical review.</li>
                </ol>
            </Text>
            <Heading el="h2" size="heading2" css={{ mt: 6, mb: 5 }}>
                Intended Medical Indication
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                Censeo Digital has been designed to capture medical information and symptoms relating to the following
                mental health conditions for further assessment by a Healthcare Professional working in a mental health
                pathway:
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                <ol className={Styles.unorderedList}>
                    <li>Bipolar disorder type I</li>
                    <li>Bipolar disorder type II</li>
                    <li>Major depressive disorder</li>
                    <li>Generalised anxiety disorder</li>
                    <li>Social anxiety disorder</li>
                    <li>Panic disorder</li>
                    <li>Obsessive-compulsive disorder</li>
                    <li>Insomnia</li>
                    <li>Psychosis</li>
                    <li>Emotionally unstable personality disorder</li>
                    <li>Eating disorders (anorexia nervosa and bulimia nervosa)</li>
                    <li>Trauma</li>
                    <li>Adjustment disorder</li>
                    <li>Alcohol abuse</li>
                    <li>Substance abuse</li>
                </ol>
            </Text>
            <Heading el="h2" size="heading2" css={{ mt: 6, mb: 5 }}>
                Contraindications{' '}
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>Censeo Digital is not intended to be used by patients:</Text>
            <Text css={{ mt: 4, mb: 3 }}>
                <ol className={Styles.unorderedList}>
                    <li>below the age of 18, or above the age of 65.</li>
                    <li>with any indication of neurological deficit.</li>
                    <li>with severe visual impairment.</li>
                    <li>without a basic understanding of the English language.</li>
                </ol>
            </Text>
            <Warnings />
            <Heading el="h2" size="heading2" css={{ mt: 6, mb: 5 }}>
                Patient Population
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                <ol className={Styles.unorderedList}>
                    <li>Patients with mental health concerns aged 18-65</li>
                    <li>Patients who have been referred to Censeo Digital by a healthcare service.</li>
                </ol>
            </Text>

            <Heading el="h2" size="heading2" css={{ mt: 6, mb: 5 }}>
                Intended Users
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                The Censeo Digital report is to be used by Healthcare Professionals with appropriate clinical training
                in mental health assessment or treatment. This includes:
            </Text>
            <table role="presentation" className={Styles.simpleTable}>
                <tr>
                    <th>Type of Healthcare Professional</th>
                    <th>Primary Use Case(s)</th>
                </tr>
                <tr>
                    <td>Mental Health Specialists within Single Point of Access (SPA) Teams</td>
                    <td>
                        Information gathering to support assessment planning and preparation, and clinical pathway
                        allocation
                    </td>
                </tr>
                <tr>
                    <td>
                        Healthcare Professionals qualified to conduct mental health assessments in front door services
                    </td>
                    <td>
                        Information gathering to support assessment planning and preparation, and clinical pathway
                        allocation
                    </td>
                </tr>
            </table>
            <Text css={{ mt: 4, mb: 3 }}>All Healthcare Professional users must be:</Text>
            <Text css={{ mt: 4, mb: 3 }}>
                <ol className={Styles.orderedList}>
                    <li>
                        Trained and competent to make autonomous clinical decisions about a patient&apos;s mental
                        health, including triage, referral, and treatment planning.
                    </li>
                    <li>
                        Responsible for making the final professional judgement on triage, referral and treatment
                        recommendations for the patient, using the Censeo Digital report as one component of a
                        comprehensive evaluation.
                    </li>
                </ol>
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                The required qualifications and training refers to the user&apos;s clinical expertise and not to
                specific training in the use of the Censeo Digital medical device itself. All users must comply with all
                relevant information management and security procedures at all times.
            </Text>
            <Heading el="h2" size="heading2" css={{ mt: 6, mb: 5 }}>
                Use Environment Including Software/Hardware
            </Heading>
            <Heading el="h3" size="heading3" css={{ mt: 6, mb: 5 }}>
                HCP
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                The Censeo Digital report is designed for use in clinical environments where mental health triage,
                assessment, or treatment decisions are made, such as mental health clinics, and remote environments
                including HCPs working from home. The report is accessed through a secure platform with appropriate
                authentication and authorisation. Compliance with data protection and security standards is required.
            </Text>
            <Heading el="h3" size="heading3" css={{ mt: 6, mb: 5 }}>
                Patient
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                Patients can complete the adaptive questionnaire anywhere with internet access with an internet enabled
                device using a compatible web browser.
            </Text>
            <Heading el="h2" size="heading2" css={{ mt: 6, mb: 5 }}>
                Operating Principle
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                The device is stand-alone software. It receives input from the user in the form of an adaptive
                questionnaire accessed via a web-based platform. Outputs are delivered in the form of a structured
                clinical report accessed via a web portal.
            </Text>
            <Heading el="h2" size="heading2" css={{ mt: 6, mb: 5 }}>
                Variants / Accessories
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>Censeo Digital is provided as a single variant in British English.</Text>
            <Text css={{ mt: 4, mb: 3 }}>
                Censeo Digital is not intended to be used with any other medical devices or accessories.
            </Text>

            <Heading el="h2" size="heading2" css={{ mt: 6, mb: 5 }}>
                How it Works:
            </Heading>
            <Heading el="h3" size="heading3" css={{ mt: 5, mb: 4 }}>
                1. Referral:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                The service user will receive a referral to their phone, they will then use this unique link to sign up
                for the Censeo platform. This will ask them to enter an email address and password & additional
                demographic information used for identification purposes.
            </Text>
            <Heading el="h3" size="heading3" css={{ mt: 5, mb: 4 }}>
                2. Reminders:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                After referral, Censeo will periodically remind them to complete the questionnaire on three occasions if
                they have not already done so. They can complete the questionnaire or opt out of these reminders which
                are available on the reminder SMS.
            </Text>
            <Heading el="h3" size="heading3" css={{ mt: 5, mb: 4 }}>
                3. Questionnaire:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                They will answer questions regarding their mental health symptoms and other related factors such as
                their employment situation & current social context. These questions presented are adaptive based on
                their previously chosen answers.
            </Text>
            <Heading el="h3" size="heading3" css={{ mt: 5, mb: 4 }}>
                4. Report Generation:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                Censeo analyses the responses and, once complete, produces a comprehensive mental health report which is
                delivered to the appropriate mental health service provider.
            </Text>
            <Heading el="h3" size="heading3" css={{ mt: 5, mb: 4 }}>
                5. Crisis Support:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                Throughout the questionnaire, crisis support information and contact details are prominently displayed
                for immediate access if needed. This information is in the top left-hand corner of your screen available
                after clicking on the logo shown for urgent help.
            </Text>
            <Heading el="h3" size="heading3" css={{ mt: 5, mb: 4 }}>
                6. Review Process:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                Only fully completed assessments are sent to and reviewed by clinicians. Partial or incomplete
                assessments are not transmitted and therefore not evaluated.
            </Text>

            <Heading el="h2" size="heading2" css={{ mt: 6, mb: 5 }}>
                Decommissioning/uninstalling
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                If you wish to discontinue use of Censeo you can deactivate your account by contacting{' '}
                <a href="mailto:support@censeo.co.uk">support@censeo.co.uk</a> for further assistance.
            </Text>
            <Heading el="h2" size="heading2" css={{ mt: 6, mb: 5 }}>
                Product Specification:
            </Heading>
            <table role="presentation" className={Styles.simpleTable}>
                <tr>
                    <td>Hardware Requirement</td>
                    <td>Mobile phone, tablet, or desktop</td>
                </tr>
                <tr>
                    <td>Internet access required</td>
                    <td>
                        Yes, Censeo requires access to the internet to view the online version of the mental health
                        report. The PDF in some occasions may be available offline.
                    </td>
                </tr>
                <tr>
                    <td>Minimum Internet browser version requirements</td>
                    <td>
                        Censeo is compatible with the following browsers:{' '}
                        <ul className={Styles.unorderedList}>
                            <li>Chrome</li>
                            <li>Firefox </li>
                            <li>iOS Safari</li>
                            <li>Opera </li>
                            <li>Opera mobile </li>
                            <li>Android - Chrome browser</li>
                            <li>Android - Firefox browser</li>
                            <li>Edge </li>
                            <li>Samsung browser</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>Support Languages</td>
                    <td>en-GB</td>
                </tr>
            </table>
            <IncidentReporting />
            <SoftwareInformation />
        </Page>
    )
}

export default ClinicianIFU
