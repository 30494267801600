import React from 'react'
import { Page, Heading, Text, Link } from '@psyomics/components'

import Styles from './PatientIFU.module.scss'
import Header from 'ui/app/components/Header'
import {
    ConditionsCoverage,
    DecommissioningUninstalling,
    ForeseeableMedicalEmergencySituations,
    IncidentReporting,
    ProductSpecification,
    ScopeAndLimitations,
    SoftwareInformation,
    Warnings,
} from './shared/IFUCommon'

interface PatientIfuProps {
    showMenu?: boolean
}

const WhatIsCenseo: React.FC = () => {
    return (
        <>
            <Heading el="h2" size="heading2" css={{ mt: 6, mb: 5 }}>
                What is Censeo?
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                The &apos;Censeo Digital&apos; medical device is software intended for use as a clinical decision
                support tool for mental health concerns in patients/service users who have been referred to Censeo by a
                healthcare service. This software is intended to be used by patients/service users aged 18-65 anywhere
                in which they have internet access with an internet-enabled device.
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                This medical device is inappropriate for being used to make a diagnosis or as a risk assessment tool in
                place of a clinician directly assessing a patient. This medical device should not be used as a crisis
                support tool.{' '}
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                This medical device software uses patients&apos; answers to produce a mental health report. The report
                informs clinicians in making triage, assessment or treatment decisions related to mental health, by
                providing a consistent and comprehensive set of data to assist their decision-making.
            </Text>
        </>
    )
}

const IntentUseEnvironment: React.FC = () => {
    return (
        <>
            <Heading el="h2" size="heading2" css={{ mt: 6, mb: 5 }}>
                Intended Use Environment
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                Service users can complete the adaptive questionnaire anywhere with internet access and an
                internet-enabled device
            </Text>
        </>
    )
}

const Contraindications: React.FC = () => {
    return (
        <>
            <Heading el="h2" size="heading2" css={{ mt: 6, mb: 5 }}>
                Contraindications for use:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                <ol className={Styles.unorderedList}>
                    <li>Using the device with any indication of neurological deficit.</li>
                    <li>Using the device as a crisis support tool.</li>
                    <li>Using Censeo Digital without a basic understanding of the English language.</li>
                    <li>Using Censeo Digital on behalf of someone else.</li>
                </ol>
            </Text>
        </>
    )
}

const HowItWorks: React.FC = () => {
    return (
        <>
            <Heading el="h2" size="heading2" css={{ mt: 6, mb: 5 }}>
                How it Works:
            </Heading>
            <Heading el="h3" size="heading3" css={{ mt: 5, mb: 4 }}>
                1. Referral:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                You will receive a referral to the phone number given to us by your mental health service. This message
                will provide you with a unique link that can be used to sign up for the Censeo platform. You need to
                enter an email address and a password that satisfies the requirements. If you have previously
                registered, after clicking on the link in the SMS you will be prompted to log in to your account.
            </Text>
            <Heading el="h3" size="heading3" css={{ mt: 5, mb: 4 }}>
                2. Reminders:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                After you have been referred, we will periodically remind you to complete the questionnaire if you have
                not already done so, once the questionnaire is complete this will not occur. These reminders will
                automatically stop after three attempts, but if you wish to opt out you can do this by using the opt-out
                link on any reminder SMS.
            </Text>
            <Heading el="h3" size="heading3" css={{ mt: 5, mb: 4 }}>
                3. Questionnaire:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                Once you have an account, you will answer questions about your presenting concerns, current living
                situation and symptoms you may be experiencing. You will need to answer or skip every question to
                complete the questionnaire, if you do not know the exact answer please give your best guess.
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                Each question has a required input. By entering this information you will be sent to the next question.
                You can also navigate through the questions by using the buttons in the bottom right-hand corner of the
                screen.
            </Text>
            <Heading el="h3" size="heading3" css={{ mt: 5, mb: 4 }}>
                4. Report Generation:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                Censeo analyses the responses to the answers and once complete produces a comprehensive mental health
                report which is delivered to your mental health service provider securely.
            </Text>
            <Heading el="h3" size="heading3" css={{ mt: 5, mb: 4 }}>
                5. Crisis Support:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                Throughout the questionnaire, crisis support information and contact details are prominently displayed
                for immediate access if needed. This information is in the top left-hand corner of your screen available
                after clicking on the logo shown for urgent help.
            </Text>
            <Heading el="h3" size="heading3" css={{ mt: 5, mb: 4 }}>
                6. Review Process:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                Only fully completed assessments are sent to and reviewed by clinicians. Partial or incomplete
                assessments are not transmitted and therefore not evaluated.
            </Text>
        </>
    )
}

const PerformanceCharacteristics: React.FC = () => {
    return (
        <>
            <Heading el="h2" size="heading2" css={{ mt: 6, mb: 5 }}>
                Performance Characteristics:
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                If the software is available, Censeo is properly installed and working. If an unforeseen issue occurs
                while you are using Censeo, Censeo will notify you of this via an error message.
            </Text>
            <Text css={{ mt: 4, mb: 3 }}>
                Maintenance and updates are carried out on Censeo to ensure the device is as safe as possible.
                Maintenance occurs on an ad hoc basis. Maintenance updates happen on a new version of the device (which
                is then updated as the current version which you use) rather than happening on the “live” device on the
                website. As such, any maintenance updates do not impact your ability to use the current version of the
                device.
            </Text>
        </>
    )
}

const FrequentlyAskedQuestions: React.FC = () => {
    return (
        <>
            <Heading el="h2" size="heading2" css={{ mt: 6, mb: 5 }}>
                Frequently Asked Questions
            </Heading>
            <Heading el="h3" size="heading3" css={{ mt: 5, mb: 4 }}>
                Q. What is Censeo and why have I been asked to do it?
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                A. Censeo is a set of questions about your mental health. These questions were designed by psychiatrists
                and psychologists to help them more of an understanding of your mental health and what you want from
                treatment.
                <br />
                When you finish the questionnaire, your mental health team get a report based on your answers. This
                helps them understand more about you so you can get the most out of your next meeting with a clinician.
            </Text>
            <Heading el="h3" size="heading3" css={{ mt: 5, mb: 4 }}>
                Q. How long is the assessment?
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                A. The assessment adapts based on your answers, so will be unique to you. It can range between 20
                minutes to an hour or more.
            </Text>
            <Heading el="h3" size="heading3" css={{ mt: 5, mb: 4 }}>
                Q. Do I have to fill it in all at once?
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                A. The assessment adapts based on your answers, so will be unique to you. It can range between 20
                minutes to an hour or more.
            </Text>
            <Heading el="h3" size="heading3" css={{ mt: 5, mb: 4 }}>
                Q. What happens if I do not complete the assessment?
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                A. If you do not complete Censeo, nothing changes in terms of the care you receive. It just helps your
                clinicians get more of a picture of what&apos;s going on for you before your next meeting. This will
                help you both make the most of the conversation you have.
            </Text>
            <Heading el="h3" size="heading3" css={{ mt: 5, mb: 4 }}>
                Q. I feel like I am struggling to keep myself safe. Can you help?
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                A. We are sorry to hear you feel like this and it&apos;s very important to us that you can keep yourself
                safe at this time. We are not regularly monitoring these answers, so if you feel like you need help with
                this, please use the crisis icon in the top left of the screen. This will display some numbers you can
                call who will help you immediately. See <Link href="#appendix-1">Appendix 1</Link>.
            </Text>
            <Heading el="h3" size="heading3" css={{ mt: 5, mb: 4 }}>
                Q. Is my data safe?
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                A. Censeo is a Medical Device registered under the MHRA, all your data is securely stored and your
                report is only shared with your mental health team. Sometimes we may use anonymised data to support our
                research and help us improve the assessment.
            </Text>
        </>
    )
}

const YourPrivacyDataSecurity: React.FC = () => {
    return (
        <>
            <Heading el="h2" size="heading2" css={{ mt: 6, mb: 5 }}>
                Your Privacy and Data Security
            </Heading>
            <Text css={{ mt: 4, mb: 3 }}>
                Censeo Digital is committed to protecting your privacy. Your responses are securely stored and
                encrypted. Only authorised healthcare providers have access to your assessment results. For detailed
                information on data privacy, refer to our Privacy Policy or contact us directly.
            </Text>
        </>
    )
}

const Appendix1: React.FC = () => {
    return (
        <div id="appendix-1">
            <Heading el="h2" size="heading2" css={{ mt: 6, mb: 5 }}>
                Appendix 1
            </Heading>
            <div className={Styles.appendixImages}>
                <img className={Styles.appendixImage} src="./sessions.png" alt="Questionnaire" />
                <img className={Styles.appendixImage} src="./emergency.png" alt="Emergency details" />
            </div>
        </div>
    )
}

const PatientIFU: React.FC<PatientIfuProps> = ({ showMenu = false }) => {
    return (
        <Page width="wide" header={showMenu ? <Header patientFacing={true}></Header> : <></>}>
            <Heading el="h1" size="heading1" css={{ mt: 5, mb: 5 }}>
                Product information
            </Heading>

            <WhatIsCenseo />
            <ScopeAndLimitations />
            <IntentUseEnvironment />
            <ConditionsCoverage />
            <Contraindications />
            <HowItWorks />
            <PerformanceCharacteristics />
            <Warnings />
            <ForeseeableMedicalEmergencySituations />
            <DecommissioningUninstalling />
            <FrequentlyAskedQuestions />
            <YourPrivacyDataSecurity />
            <ProductSpecification />
            <IncidentReporting />
            <SoftwareInformation />
            <Appendix1 />
        </Page>
    )
}

export default PatientIFU
